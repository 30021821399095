import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { Button } from '../interacion/button/Button';


import FooterLinks from './components/FooterLinks';
import FooterCTA from './components/FooterCTA';
import FooterSocialMedia from './components/FooterSocialMedia';

function Footer() {
    return (
      <footer className='footer-container'>

        {/* <FooterCTA/> */}

        {/* <FooterLinks/> */}

        {/* Social media links */}
        <section className="social-media">
            <div className="social-media-wrap">
            <a href="/"><img className="logo footer-logo" src="./images/logo-schreuder-customs-transparent.png" width="200px"></img></a>
                {/* <div className="footer-logo">
                    <Link to='/' className="social-logo">
                        TRVL <i className="fab fa-typeo3"></i>
                    </Link>
                </div> */}
                <small className="website-rights">Schreuder Customs © 2021</small>
                
                {/* <FooterSocialMedia /> */}

            </div>
        </section>
      </footer>
    );
  }

export default Footer
