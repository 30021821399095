import React from 'react';
import '../../../../App.css'
import { Button } from '../../interacion/button/Button';
import './HeaderSection.css';
import InfoEmail from './company_info/InfoEmail';
import InfoLocations from './company_info/InfoLocations';

function HeaderSection(props) {
    return (
        <div className='hero-container-wrapper'>
            <div className='hero-container'>
                <img className="background" src="./images/img-header-spanen.jpeg"></img>

                <div className='hero-content'>
                    {/* <video src='/videos/video-2.mp4' autoPlay loop muted /> */}
                    <img src="./images/logo-schreuder-customs-transparent.png"></img>
                    {/* <p>Generic slogan here</p> */}
                    
                    {/* <div className="hero-btns">
                        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>PORTFOLIO</Button>
                        <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>OFFERTE AANVRAGEN <i className="fas fa-arrow-circle-right arrow"></i></Button>
                    </div> */}

                    <div className="contact-info-wrapper">
                        <ul className="contact-info">
                            <li><InfoEmail index='0' /></li>
                            <li><InfoEmail index='1' /></li>
                            <li><InfoLocations index='0' /></li>
                        </ul>
                        {/* <ul className="contact-info">
                            <li><InfoLocations index='0' /></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderSection
