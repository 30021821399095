import React from 'react';
import { Link } from 'react-router-dom';

function InfoEmail(props) {

  const emailArray = [
    'info@schreudercustoms.nl',
    'offerte@schreudercustoms.nl'
  ]

  var email = emailArray[props.index];

  if (!email)
  {
    console.error("No email found with index: " + props.index);
    return <div></div>
  }
  else
  {
    return (
      <div><i className="fas fa-envelope-square"></i> <a>{email}</a></div>
    );
  }

}

export default InfoEmail;