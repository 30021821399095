import React from 'react';
import Navbar from './components/layout/navbar/Navbar';
import './App.css';
import Home from './components/pages/Home';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Factuur from './components/pages/Factuur';
import Portfolio from './components/pages/Portfolio';
import Contact from './components/pages/Contact';
import Footer from './components/layout/footer/Footer';

window.basicText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

window.maxWidth = 1220;

function App() {
    return (
    <>
    <Router>
        <Navbar />
        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/factuur' exact component={Factuur} />
            <Route path='/portfolio' exact component={Portfolio} />
            <Route path='/contact' exact component={Contact} />
        </Switch>
        <Footer />
    </Router>
    </>
    );
}

export default App;