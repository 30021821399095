import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';

import NavbarButtons from './NavbarButtons';

function Navbar() {
    const [click, setClick] = useState(false);// Input variable
    const [button, setButton] = useState(true);// Button variable

    // Set the variable to the opposite of what it is currently
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    // Dont show the button on Mobile
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    // This is an fix for the buttons to update when the page resizes
    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className="navbar">
                {/* <div className="navbar-container"> */}

                    {/* Logo in the Navbar */}
                    {/* <a href="/"><img className="logo navbar-logo" src="./images/logo-schreuder-customs-transparent.png" width="200px"></img></a> */}
                    
                    {/* <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>{/* When a pages loads close the menu with "closeMobileMenu" */}
                        {/* Schreuder Customs */}
                    {/* </div></Link> */}

                    {/* <NavbarButtons handleClick={handleClick} click={click} closeMobileMenu={closeMobileMenu} /> */}

                    {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>} */}
                {/* </div> */}
            </nav>
        </>
    )
}

export default Navbar
