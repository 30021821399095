import React from 'react';
import { Link } from 'react-router-dom';

function InfoLocations(props) {

  const array = [
    'Tomatenmarkt 44b 1681PH, Zwaagdijk-Oost'
  ]

  var value = array[props.index];

  if (!value)
  {
    console.error("No email found with index: " + props.index);
    return <div></div>
  }
  else
  {
    return (
      <div><i className="fas fa-map-marker-alt"></i> <a>{value}</a></div>
    );
  }

}

export default InfoLocations;