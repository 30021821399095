import React from 'react';
import '../../../../../App.css'
import { Button } from '../../../interacion/button/Button';
import './CompanyStory.css';

var textTitle = 'Welkom';

var name = <i className="text_bold">Schreuder Customs</i>;//<i className="underline_text">Schreuder Customs</i>

var companyStory = " is een jong bedrijf actief in de metaalsector. Ervaren in reparatie en revisie voor diverse bedrijfstakken. Hèt adres voor de 'Allround' metaalbewerking. Altijd bereikbaar voor advies.";//

if (!companyStory)
    companyStory = window.basicText;

function CompanyStory() {
    return (
        <div className='company-info'>

            <div className='company-info-content'>
                
                <h1>{textTitle}</h1>

                <hr class="textUnderLine"></hr>

                <div className='company-info-story'>
                
                    <p>{name} {companyStory}</p>

                </div>

            </div>

            
        </div>
    )
}

export default CompanyStory
