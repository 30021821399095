import React from 'react';
import HeaderSection from '../layout/pages/home/HeaderSection';
import CompanyStory from '../layout/pages/home/company_info/CompanyStory';
import Cards from '../layout/pages/portfolio/Cards';

function Home () {
    return (
        <>
            <div className="page">
            <HeaderSection />

            <CompanyStory />
            
            <Cards />
            </div>
        </>
    );
};

export default Home;