import React from 'react'
import CardItem from './CardItem';
import './Cards.css';

var textTitle = 'Werkzaamheden';//Portfolio

var before = 'Custom ';
var after = '';

function Cards() {
    return (
      <div className='cards'>
        <h1>{textTitle}</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardItem
                title={before + 'Laswerk' + after}
                src='/images/portfolio/custom-laswerk/custom-laswerk-as-01.jpeg'
                label='Product'
                text={window.basicText}
                date='asd'
                path='/factuur'
              />
              <CardItem
                title={before + 'Motor Onderdelen' + after}
                src='/images/portfolio/custom-motor-onderdelen/custom-motor-onderdelen-project-01.jpeg'
                label='Eigen Projecten'
                text={window.basicText}
                date='Product'
                path='/portfolio'
              />
              <CardItem
                title={before + 'Race Onderdelen' + after}
                src='/images/portfolio/custom-race-onderdelen/custom-race-onderdelen-handrem.jpeg'
                label='Eigen Projecten'
                text={window.basicText}
                date='Product'
                path='/portfolio'
              />
              <CardItem
                title={before + 'Tuin Decoratie' + after}
                src='/images/portfolio/custom-tuin-decoratie/custom-tuin-decoratie-rozenboog-01.jpeg'
                label='Product'
                text={window.basicText}
                date='asd'
                path='/factuur'
                zoom={150}
              />
            </ul>
          </div>
        </div>
      </div>
    );
  }

export default Cards
