import React from 'react';
import { Link } from 'react-router-dom';
// to={props.path}
function CardItem(props) {

  var s;
  

  if (props.zoom != undefined)
  {
    var zoom = -(props.zoom / 6);
      //props.zoom = props.zoom / 100;
      s = {width: props.zoom + "%", left: zoom + "%"};//{transform: "scale("+ (props.zoom / 100) + ")"};
    }

  return (
    <>
      <li className='cards__item' title="Onder contructie"> 
        <Link className='cards__item__link'>
          
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Image'
              src={props.src}
              style={s}
            />

            {/* <span>TESTTTTT</span> */}
          </figure>

          {/* Info */}
          <div className='cards__item__info'>
            <h5 className='cards__item__title'>{props.title}</h5>
            {/* <p className='cards__item__text'>{props.text}</p> */}
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
